*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}
body{
    width: 100vw;
    overflow-x: hidden;
    
}

h1,h2,.title{
    font-family: 'Montaga', serif !important;
}

button{
    cursor: pointer;
}
a{
    text-decoration: none;
}