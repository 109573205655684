body{
  /* background-color:#5A60F1; */
}

.mainContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:50vw;

  }
  .mainContainer span{
    color: #03256C;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    /* width:10%; */
  }
.lessonCardContainer{
    padding:20px;
    display:flex;
    flex-wrap: wrap;
    gap:10px;
}
.lessonCard{
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 17px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.31);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position:relative;
}

.lessonCardInner{
    width: 236px;
    height: 292px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items:center;
    padding:20px;
    background-color: white;
    overflow-y: scroll;
    cursor:pointer;
}
.inner1{
    color: #000;
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-transform: capitalize;
}
.inner2{
    width: 237px;
height: 290px;
flex-shrink: 0;
color: #000;
font-family: Inter;
font-size: 6px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
padding:20px;
}
div span {
    color: #fff;
      /* font-family: Source Sans Pro; */
      font-size: 22px;
      /* font-style: normal; */
      /* font-weight: 700; */
      line-height: normal;
      text-transform: capitalize;
}
.arrowRight img {
    
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      margin-top: 2px;
    
  }
  .noData{
    margin-top:20px;
  }
  .questionTag{
    width: 235px;
    height: 60px;
    flex-shrink: 0;
    background-color:#FFCDEA;
    border-radius: 20px;
    padding:15px;
    font-size: 12px;
    position:absolute;
    bottom:20px;
    cursor:pointer;
  }
  .questionTag div:nth-child(1){
    font-weight: 700;
  }
  .questionTag div:nth-child(2){
    font-size: 10px;
  }
  @media (max-width:1420px){
      .mainContainer{
        /* width:50%; */
      }
  }
  /* @media (max-width:1350px){
    .lessonCardInner{
      width: 296px;
      height: 352px;
    }
  }
  @media (max-width:1120px){
    .lessonCardInner{
      width: 266px;
      height: 332px;
    }
  } */