* {
    box-sizing: border-box;
    margin:0;
    padding:0;

}
.container{
    position: relative;
    padding-top: 80px;
    height: fix-content; 
    width:100vw;
    /* background-color: #80DDF0; */


}
.containerInner{
    
    height: 90%;
    width: 90%;
    margin: 62px auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:50px;
    /* background-color: #f8f9fa; */


}
.planCard{
    height:444px;
    width:30%;
    background-color:#5A60F1;;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.cardTagImg{
    height:80px;
    width:100px;
}
p.cardHeading:nth-child(1){
    height:40px;
    width:100%;
    background-color:white;
    color:#5A60F1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:1em;
    letter-spacing: 1px;
    font-weight: 700;

}
p.cardHeading:nth-child(2){
    font-weight: 600;
    color:white;
    text-align: center;
    margin-top: 15%;
    font-size: 2em;
}
p.cardHeading:nth-child(3){
    text-align: center;
    margin-top:2%;
    font-size: 2em;
    font-weight: 600;
    color:white;
}
p.cardHeading:nth-child(4){
    font-size: 0.8em;
    margin-top:10%;
    color:white;
    padding:20px;
    text-align: center;
    line-height: 1.5em;
}
.buyButton{
    background-color:#FFCDEA;
    border:none;
    width:fit-content;
    height:fit-content;
    position:absolute;
    bottom:50px;
    font-weight: 600;
    color:#5A60F1;
    font-size:20px;
    padding: 10px 30px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #03256C;
    width:55%;
    display:flex;
    justify-content: center;
    align-items:center;

}
.subscribed{
    border:none;    
    background-color:#FFCDEA;
    width:fit-content;
    height:fit-content;
    position:absolute;
    bottom:50px;
    font-weight: 600;
    color:#5A60F1;
    font-size:20px;
    padding: 10px 30px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #03256C;
    width:55%;
    display:flex;
    justify-content: center;
    align-items:center;
}
.btnBack {
    margin-left: 20%;
    color:black;
  }
  .btnBack img {
    height: 10px;
    width: 10px;
    margin-right: 5px;
  }
  .backButton {
    font-size: 1.5em;
    margin-bottom: 20px;
    color:black;
  }
  @media (max-width:900px){
    .planCard{
        height:450px;
        width:40%;
    }
    
  }
  @media (max-width:1020px){
    .buyButton{
        font-size:18px;
    }
    .subscribed{
        font-size:18px;
    }
  }
  @media (max-width:768px){
    .buyButton{
        bottom:20px;
    }
    .buyButton{
        font-size:15px;
    }
    .subscribed{
        bottom:20px;
        font-size: 15px;
    }
    p.cardHeading:nth-child(3){
        font-size:2.5em;
    }
    p.cardHeading:nth-child(4){
        /* font-size:0.5em; */
    }
    .btnBack {
        margin-left: 12%;
        margin-bottom:-60px;
        color:black;
        font-size:12px;
      }
  }
  @media (max-width:500px){
    .buyButton{
        bottom:10px;
    }
    .subscribed{
        bottom:5px;
    }
    .planCard{
        height:max-content;
    }
    .containerInner{
        margin: 120px 20px;
        flex-direction:column;
        padding:0;
    }
    .planCard{
        height:400px;
        width:70%;
    }
  }
  @media (max-width:430px){
    
   
    p.cardHeading:nth-child(1){
        font-size:0.7em;
        height:30px;
    }
    p.cardHeading:nth-child(3){
        font-size:1.8em;
    }
    p.cardHeading:nth-child(4){
        /* font-size:0.3em; */
    }
    .btnBack {
        margin-left: 12%;
        margin-bottom:-60px;
        color:black;
        font-size:12px;
      }
    }

    @media (max-width:320px){
        p.cardHeading:nth-child(1){
            font-size:16px;
            padding:5px;
        }
        .planCard{
            width:90%;
            height: 400px;
   
       }
       .btnBack {
        margin-left: 12%;
        margin-bottom:-60px;
        color:black;
        font-size:12px;
      }
    }